<template>
  <div :class="$style.container">
    <img :class="$style.broadcast" :src="require('@assets/images/livefeedtest.png')" />
    <div :class="[$style.overlay, { [$style.hideComments]: !isChatVisible }]">
      <div :class="$style.header">
        <v-avatar :class="$style.avatar" :src="mock.ownerAvatar" size="xs" progress-color="white" />
        <span :class="$style.ownerName">{{ mock.ownerName }}</span>
        <span :class="$style.viewers">
          <icon name="eye" width="14" height="10" />
          {{ mock.viewersAmount }}
        </span>
        <span v-if="mock.isLive" :class="$style.status">Live</span>
        <v-button size="md" color="transparent" is-disabled-padding @click="onMenu">
          <icon name="dots-3" width="2" height="14" />
        </v-button>
        <v-button
          size="md"
          color="transparent"
          :class="$style.close"
          is-disabled-padding
          to="/"
          @click="onClose"
        >
          <icon name="close" width="14" height="14" />
        </v-button>
      </div>
      <div v-show="isChatVisible" :class="$style.comments" ref="comments">
        <div
          v-for="{ name, message, avatar, id } in mock.comments"
          :key="id"
          :class="$style.comment"
        >
          <v-avatar :class="$style.commnetAvatar" :src="avatar" size="xs" progress-color="white" />
          <span :class="$style.commentName">{{ name }}:</span>
          <span>{{ message }}</span>
        </div>
      </div>
      <div :class="$style.products">
        <span :class="$style.productsTitle">I am wearing now:</span>
        <div :class="$style.productsList">
          <div :class="$style.product" v-for="product in mock.products" :key="product.id">
            <v-image
              :class="$style.productImage"
              :src="media(product, 'content_url')"
              :srcset="media(product, 'preview_url')"
            />
            <span :class="$style.productPrice">{{ currency }}{{ product.price }}</span>
            <v-button
              :class="$style.productAdd"
              size="md"
              color="transparent"
              is-disabled-padding
              @click="addProductToCart(product)"
            >
              {{ $t('addToCart') }}
            </v-button>
          </div>
        </div>
      </div>
      <div :class="$style.footer">
        <text-input
          :class="$style.input"
          :placeholder="$t('writeComment')"
          v-model="comment"
          @enter="onComment($event)"
        />
        <v-button size="md" color="transparent" is-disabled-padding @click="onShare">
          <icon name="share-2" width="18" height="18" />
        </v-button>
        <v-button size="md" color="transparent" is-disabled-padding @click="onCart">
          <icon name="shop-2" width="20" height="18" />
        </v-button>
        <div :class="$style.tags">
          <v-button size="md" color="transparent" is-disabled-padding @click="onShop">
            <icon name="tags" width="18" height="18" />
          </v-button>
          {{ mock.tags }}
        </div>
      </div>
      <share-drawer :is-visible.sync="isShareVisible" meta-description="" meta-media="" />
      <shop-drawer :is-visible.sync="isShopVisible" :products="mock.products" />
      <v-drawer :is-visible.sync="isMenuVisible">
        <transition-group tag="div" name="scroll-group" appear>
          <v-list key="list" :class="$style.list" :list="drawerMenu" @click="onSelectMenu" />
        </transition-group>
      </v-drawer>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VImage from '@elements/VImage.vue'
import VList from '@elements/VList.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import currencies from '@utils/currencies'
import ShareDrawer from '@drawers/list/ShareDrawer.vue'

import ShopDrawer from '@drawers/list/ShopDrawer.vue'
import testProducts from './mock.json'

export default Vue.extend({
  name: 'Broadcast',
  components: {
    TextInput,
    ShopDrawer,
    ShareDrawer,
    VAvatar,
    VButton,
    VImage,
    VDrawer,
    VList,
  },
  inject: {
    cartProvider: {
      default: () => ({
        isCartVisible: false,
      }),
    },
  },
  data() {
    return {
      isChatVisible: true,
      isShareVisible: false,
      isShopVisible: false,
      isMenuVisible: false,
      comment: '',
      mock: {
        tags: 24,
        ownerName: 'Darina.588',
        ownerAvatar: '',
        viewersAmount: 768,
        isLive: true,
        comments: [],
        products: testProducts,
      },
    }
  },
  computed: {
    ...mapGetters('apps', ['getCurrencyCode', 'getCurrentAppsUser']),
    currency() {
      return currencies[this.getCurrencyCode]?.symbol
    },
    userAvatar() {
      return this.getCurrentAppsUser?.avatar?.content_url ?? ''
    },
    userName() {
      return this.getCurrentAppsUser?.name ?? ''
    },
    drawerMenu() {
      return [
        { name: 'chat', title: 'Turn off the Chat' },
        { name: 'share', title: 'Share...' },
        { name: 'shareFrom', title: 'Share from 3:17...' },
        { name: 'cancel', title: 'Cancel' },
      ]
    },
  },
  methods: {
    ...mapMutations('products', ['addProductToCart']),
    ...mapMutations(['setHeader', 'setFooter']),
    onMenu() {
      this.isMenuVisible = !this.isMenuVisible
    },
    onClose() {
      console.log('close')
    },
    onShare() {
      this.isShareVisible = !this.isShareVisible
    },
    onCart() {
      this.cartProvider.isCartVisible = true
    },
    onShop() {
      this.isShopVisible = !this.isShopVisible
    },
    media(product, type) {
      return product?.media_objects ? product?.media_objects[0]?.[type] ?? '' : ''
    },
    onComment(comment) {
      this.mock.comments.push({
        name: this.userName,
        avatar: this.userAvatar,
        message: comment,
        id: Math.floor(Math.random() * 45),
      })
      this.onScrollBottom()
      this.comment = ''
    },
    onScrollBottom() {
      this.$nextTick(() => {
        const container = this.$refs.comments
        if (container) {
          container.scroll({
            top: container.scrollHeight,
            behavior: 'instant',
          })
        }
      })
    },
    onSelectMenu(name) {
      switch (name) {
        case 'chat':
          this.isChatVisible = !this.isChatVisible
          this.isMenuVisible = !this.isMenuVisible
          break
        case 'share':
          this.isMenuVisible = !this.isMenuVisible
          this.isShareVisible = !this.isShareVisible
          break
        default:
          this.isMenuVisible = !this.isMenuVisible
      }
    },
  },
  created() {
    this.setHeader(false)
    this.setFooter(false)
  },
  beforeRouteLeave(_, __, next) {
    this.setHeader(true)
    this.setFooter(true)
    next()
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $secondary;
}

.broadcast {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 60px auto min-content 36px;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  transform: translateX(-50%);

  &.hideComments {
    grid-template-rows: 60px min-content 36px;
  }
}

.header {
  display: grid;
  grid-gap: 9px;
  grid-template-columns: 36px 1fr auto auto 36px 36px;
  align-items: center;
  padding: 0 12px;
  color: white;
}

.ownerName {
  font-weight: 500;
  font-size: 15px;
}

.viewers {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.status {
  padding: 0 9px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background-color: $error;
  border-radius: 9px;
}

.close {
  color: white !important;
}

.comments {
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 10px;
  align-self: flex-end;
  max-height: 100%;
  padding: 0 9px;
  overflow: auto;
}

.comment {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 36px auto 1fr;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 12px;
  text-shadow: 1px 1px 0 #000000;

  &Name {
    font-weight: 500;
  }
}

.products {
  display: grid;
  grid-gap: 9px;
  margin: 0 9px;
  padding: 9px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  &Title {
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
  &List {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-gap: 6px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.product {
  position: relative;
  width: 120px;
  height: 120px;

  &Image {
    display: block;
    width: 120px !important;
    height: 120px !important;
    overflow: hidden;
    border-radius: 6px;
  }

  &Price {
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 0 6px;
    color: $secondary;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background-color: white;
    border-radius: 3px 9px 9px 9px;
  }

  &Add {
    position: absolute;
    bottom: 3px;
    left: 3px;
    width: auto;
    padding: 0 6px !important;
    color: white !important;
    font-weight: 500;
    font-size: 11px !important;
    line-height: 18px !important;
    background-color: $secondary !important;
    border-radius: 9px 9px 9px 3px !important;
  }
}

.timeline {
}

.footer {
  display: grid;
  grid-gap: 23px;
  grid-template-columns: 1fr auto auto auto;
  padding: 0 9px;
}

.input input {
  height: 36px;
  border-radius: 21px;
}

.tags {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.list {
  box-shadow: inset 0 1px 0 $secondary-light;
}
</style>
