<template>
  <v-drawer
    :is-visible.sync="isDrawerVisible"
    :title="$t('select.network')"
    @update:is-visible="$emit('update:is-visible', $event)"
    @scrollbar="$bus.$emit('scrollbar', { isVisible: $event })"
  >
    <transition-group tag="div" name="scroll-group" appear>
      <v-list key="list" :class="$style.list" :list="drawerSocials" @click="onSelectSocial" />
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VList from '@elements/VList.vue'

export default Vue.extend({
  name: 'ShareDrawer',
  components: { VDrawer, VList },
  props: {
    isVisible: Boolean,
    metaDescription: String,
    metaMedia: String,
    postId: [String, Number],
  },
  data() {
    return {
      socialLinks: {
        vk: 'https://vk.com/share.php?url=',
        facebook: 'https://www.facebook.com/sharer.php?src=sp&u=',
        ok: 'https://connect.ok.ru/offer?url=',
        twitter: 'https://twitter.com/share?url=',
        tumblr: 'https://www.tumblr.com/share/link?url=',
      },
    }
  },
  computed: {
    ...mapGetters('apps', ['getCurrentAppsUser']),
    //! [Vue warn]: Mutating a prop that parent component re-renders
    isDrawerVisible: {
      get() {
        return this.isVisible
      },
      set(isVisible) {
        this.$emit('is-visible', isVisible)
      },
    },
    drawerSocials() {
      return [
        {
          name: 'vk',
          title: this.$t('vk'),
          icon: 'vk',
        },
        {
          name: 'ok',
          title: this.$t('ok'),
          icon: 'ok',
        },
        {
          name: 'facebook',
          title: 'Facebook',
          icon: 'facebook',
        },
        {
          name: 'twitter',
          title: 'Twitter',
          icon: 'twitter',
        },
        // {
        //   name: 'tumblr',
        //   title: 'Tumblr',
        //   icon: 'tumblr',
        // },
      ]
    },
    userName() {
      return this.getCurrentAppsUser?.name ?? null
    },
    postIdComputed() {
      return this?.postId || this.$route.params?.id
    },
  },
  methods: {
    onSelectSocial(name) {
      const url = `${window.location.origin}/#/profile/post/${this.postIdComputed}`
      const image = this.metaMedia
      const link = encodeURI(
        `${this.socialLinks[name]}${url}&title=${this.userName}&description=${this.metaDescription}&image=${image}`
      )
      const openedWindow = window.open(
        link,
        this.userName,
        [
          'toolbar=0',
          'status=0',
          'scrollbars=1',
          'width=640',
          'height=480',
          `left=${window.screen.availWidth / 2 - 320}`,
          `top=${window.screen.availHeight / 2 - 240}`,
          'toolbar=0',
        ].join(',')
      )
      if (!openedWindow.closed) {
        this.$bus.$emit('drawer', false)
      }
    },
  },
})
</script>

<style lang="scss" module>
.list {
  box-shadow: inset 0 1px 0 $secondary-light;
}
</style>
