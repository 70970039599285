// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShareDrawer_list_3yQxx{box-shadow:inset 0 1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"list": "ShareDrawer_list_3yQxx"
};
module.exports = exports;
