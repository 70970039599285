// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShopDrawer_shop_2qej_{height:calc(100% - 70px);max-height:480px;overflow-x:hidden;overflow-y:auto}.ShopDrawer_preview_A3vtG,.ShopDrawer_shop_2qej_{display:grid;grid-template-rows:1fr min-content;border-top:1px solid #e5e3e3}.ShopDrawer_previewImage_3zFit{width:100%;height:auto;padding:15px;overflow:hidden}.ShopDrawer_previewImage_3zFit img{border-radius:6px}.ShopDrawer_previewFooter_2LWTg{display:grid;padding:15px;background-color:#fff;border-top:1px solid #e5e3e3}", ""]);
// Exports
exports.locals = {
	"shop": "ShopDrawer_shop_2qej_",
	"preview": "ShopDrawer_preview_A3vtG",
	"previewImage": "ShopDrawer_previewImage_3zFit",
	"previewFooter": "ShopDrawer_previewFooter_2LWTg"
};
module.exports = exports;
