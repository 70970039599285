<template>
  <v-drawer
    :is-visible.sync="isDrawerVisible"
    :title="title"
    :is-back="!!selectedProduct"
    @update:is-visible="$emit('update:is-visible', $event)"
    @scrollbar="$bus.$emit('scrollbar', { isVisible: $event })"
    @back="selectedProduct = null"
  >
    <transition-group tag="div" :name="transitionName" appear>
      <div :class="$style.shop" v-if="!selectedProduct" key="shop">
        <cart-product
          v-for="product in products"
          :key="product.id"
          :id="product.id"
          :class="$style.product"
          :name="product.name || ''"
          :src="media(product, 'content_url')"
          :srcset="media(product, 'preview_url')"
          :price="`${currency}${product.price || 0}`"
          @click.native="selectedProduct = product"
        />
      </div>
      <div v-if="selectedProduct" :class="$style.preview" key="product">
        <v-image
          :class="$style.previewImage"
          :src="media(selectedProduct, 'content_url')"
          :srcset="media(selectedProduct, 'preview_url')"
        />
        <div :class="$style.previewFooter">
          <v-button @click="onAddProduct(selectedProduct)" size="xl" color="primary">{{
            $t('addToCart')
          }}</v-button>
        </div>
      </div>
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VImage from '@elements/VImage.vue'
import VDrawer from '@drawers/VDrawer.vue'
import CartProduct from '@views/cart/list/CartProduct.vue'
import currencies from '@utils/currencies'

export default Vue.extend({
  name: 'ShopDrawer',
  components: { VDrawer, CartProduct, VImage, VButton },
  props: {
    isVisible: Boolean,
    products: Array,
  },
  data() {
    return {
      selectedProduct: null,
    }
  },
  computed: {
    ...mapGetters('apps', ['getCurrencyCode', 'getCurrentAppsUser']),
    //! [Vue warn]: Mutating a prop that parent component re-renders
    isDrawerVisible: {
      get() {
        return this.isVisible
      },
      set(isVisible) {
        this.$emit('is-visible', isVisible)
      },
    },
    currency() {
      return currencies[this.getCurrencyCode]?.symbol
    },
    transitionName() {
      return this.selectedProduct ? 'scroll-group' : 'scroll-horizontal'
    },
    title() {
      return this.selectedProduct ? this.selectedProduct.name : this.$t('shop')
    },
  },
  methods: {
    ...mapMutations('products', ['addProductToCart']),
    media(product, type) {
      return product?.media_objects ? product?.media_objects[0]?.[type] ?? '' : ''
    },
    onAddProduct(product) {
      this.selectedProduct = null
      this.addProductToCart(product)
    },
  },
  watch: {
    isDrawerVisible(isVisible) {
      if (!isVisible) {
        this.selectedProduct = null
      }
    },
  },
})
</script>

<style lang="scss" module>
.product {
}
.shop {
  display: grid;
  grid-template-rows: 1fr min-content;
  height: calc(100% - 70px);
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid $secondary-light;
}
.preview {
  display: grid;
  grid-template-rows: 1fr min-content;
  border-top: 1px solid $secondary-light;

  &Image {
    width: 100%;
    height: auto;
    padding: 15px;
    overflow: hidden;

    & img {
      border-radius: 6px;
    }
  }

  &Footer {
    display: grid;
    padding: 15px;
    background-color: white;
    border-top: 1px solid $secondary-light;
  }
}
</style>
